import { emiliPayments } from '../services/costco.service';
export default {
    namespaced: true,
    state: {
        isEmiliPaymentLoading: false,
        emiliPaymentResponse: null,
        emiliPaymentError: null,
    },
    mutations: { 
        emiliPaymentsStartLoading(state) {
            state.isEmiliPaymentLoading = true;
            state.emiliPaymentError = null;
            state.emiliPaymentResponse = null;
        },
        emiliPaymentResponseLoaded(state, payload) {
            state.emiliPaymentResponse = payload;
            state.isEmiliPaymentLoading = false;
        },
        emiliPaymentFailed(state, error) {
            state.emiliPaymentError = error;
            state.isEmiliPaymentLoading = false;
        }
    },
    actions: { 
        async startEmiliPayment({commit, state}, body) {
            if (state.isEmiliPaymentLoading) return;
            commit('emiliPaymentsStartLoading')
            try {
               commit('emiliPaymentResponseLoaded', await emiliPayments(body)); 
            } catch (error) {
                commit('emiliPaymentFailed', error)
            }
        }
    },
}